<template>
  <div class="result-wrap">
    <div :class="isPc ? 'result-pc' : 'result-phone'">
      <div class="goBack" style="display: none" @click="goBack">
        <van-icon name="down" color="#c90f2c" />返回继续查询
      </div>
      <div class="wrap" v-if="ascendInfo.length">
        <div class="goodInfo">
          <div class="title">商品信息</div>
          <div class="goods_wrap">
            <div class="img_wrap">
              <img :src="productInfo.img" />
            </div>
            <div class="goods_info">
              <span class="goods_info_title">{{ productInfo.name }}</span>
              <span class="goods_info_ascendnumber"
                >防伪编码：<span class="ascendnumber">{{
                  productInfo.ascendnumber
                }}</span></span
              >
              <span class="price">¥ {{ productInfo.price }}</span>
            </div>
          </div>
          <div class="logo">
            <img src="../../assets/img/zhengpin.png" alt="" />
          </div>
        </div>
        <div class="agent_wrap">
          <div class="agent-title">溯源信息</div>
          <div
            class="agent-item"
            v-for="(item, index) in ascendInfo"
            :key="index"
          >
            <div class="left">
              <span class="level">{{ item.type_name }}</span>
              <span
                class="icon"
                :class="{ 'flow-hr': index < ascendInfo.length - 1 }"
              ></span>
            </div>
            <div class="right">
              <div class="time">
                <span class="right_title">下单时间</span>
                <span class="right_result">
                  {{ timeFilter(item.creat_time) }}</span
                >
              </div>
              <div class="order_num">
                <span class="right_title">订单编号 </span>
                <span right_result> {{ item.Order_ID }}</span>
              </div>
              <div class="order_num">
                <span class="right_title">店铺编码</span>
                <span right_result> {{ item.stores_sn }}</span>
              </div>
              <div class="agent_name">
                <span class="right_title">代理名称</span>
                <span right_result> {{ item.Stores_Name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-result" v-else>
        <div class="img"></div>
        <div class="remark">
          很抱歉，暂未查询到物品信息 您可以选择<span class="btn" @click="goBack"
            >重新查询</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "anti-result",
  props: ["isPc"],
  data() {
    return {
      Users_ID: "qrbhjcw8vv",
      env: "wx_lp",
      act: "get_order_prod_ascend",
      ascendInfo: [],
      ascendResult: ""
    };
  },
  computed: {
    productInfo() {
      let data = {};
      const ascendInfo = this.ascendInfo;
      if (ascendInfo.length) {
        let img = ascendInfo[0].Products_JSON
          ? JSON.parse(ascendInfo[0].Products_JSON)
          : "";
        img = img ? (img.ImgPath.length ? img.ImgPath[0] : "") : "";
        data.price = ascendInfo[0].Products_PriceX;
        data.name = ascendInfo[0].Products_Name;
        data.img = img;
        data.ascendnumber = ascendInfo[0].prod_ascend_id;
      }
      return data;
    }
  },
  methods: {
    timeFilter(val) {
      return this.$day(new Date(val * 1000)).format("YYYY-M-d hh:mm:ss");
    },
    getAscendInfo(antNum) {
      let datas = {
        Users_ID: this.Users_ID,
        act: this.act,
        env: "wap",
        ascend_id: antNum,
        User_ID: "",
        store_id: "1",
        access_token: "",
        sortToken: "1"
      };
      let data = new FormData();
      for (let i in datas) {
        data.append(i, datas[i]);
      }
      this.$axios
        .post(
          "https://mall.xemerp.com/api/v1/get_store_pifa_assign_order.html",
          data,
          {
            headers: { "Content-Type": "multipart/form-data" }
          }
        )
        .then(res => {
          let result = res.data;
          if (result.data && result.data.length) {
            this.ascendInfo = result.data;
          }
        });
    },
    goBack() {
      this.$router.go(-1);
    }
  },

  created() {
    let data = window.localStorage.getItem("antNum");
    this.getAscendInfo(data);
  }
};
</script>

<style lang="less" scoped>
@import "./result.less";
.result-phone {
  width: 100%;
  height: calc(100vh - 132px);
  overflow-y: auto;
  background-color: #fff;
  .wrap {
    box-sizing: border-box;
    width: 100%;
  }
  .goodInfo {
    width: 100%;
    height: 372px;
    background-color: #ffffff;
    box-shadow: 0px 3px 30px 0px rgba(218, 227, 235, 0.5);
    // border-radius: 20px;
    padding: 20px 40px 40px;
    box-sizing: border-box;
    position: relative;
    .title {
      font-size: 36px;
      line-height: 60px;
      padding: 20px 0 10px;
      color: #000000;
    }
    .goods_wrap {
      display: flex;
      justify-content: space-between;
      .img_wrap {
        width: 241px;
        height: 241px;
        background-color: #f6f6f6;
        border-radius: 10px;
      }
      .goods_info {
        flex: 1;
        box-sizing: border-box;
        padding: 20px 0 20px 37px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 36px;
        .goods_info_ascendnumber {
          color: #666;
          .ascendnumber {
            color: #c90f2c;
          }
        }
        .price {
          font-size: 48px;
          line-height: 72px;
          color: #333333;
        }
      }
    }
    .logo {
      width: 181px;
      height: 204px;
      position: absolute;
      bottom: -10px;
      right: 0;
    }
  }
  .agent_wrap {
    padding: 30px;
    .agent-title {
      font-size: 42px;
      padding: 20px 0;
      margin: 20px 0;
    }
    .agent-item {
      width: 100%;
      height: 247px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 59px;
      .left {
        font-size: 36px;
        line-height: 48px;
        color: #5c6066;
        flex: 1;
        padding-right: 24px;
        display: flex;
        justify-content: space-between;
        padding-top: 67px;
        position: relative;
        .level {
          font-size: 36px;
          color: #5c6066;
        }
        .icon {
          width: 20px;
          height: 20px;
          box-shadow: 0px 3px 10px 0px rgba(81, 84, 91, 0.1);
          border-radius: 50%;
          background-color: #c90f2c;
          border: solid 6px #ffffff;
          margin-top: 8px;
          position: absolute;
          right: 20px;
          z-index: 10;
          &.flow-hr::after {
            content: "";
            position: absolute;
            top: 25px;
            left: 8px;
            height: 280px;
            border-left: 8px dotted #f0f2f5;
            z-index: 0;
          }
        }
      }
      .right {
        box-sizing: border-box;
        width: 800px;
        height: 100%;
        background-color: #ffffff;
        box-shadow: 0px 3px 16px 0px rgba(218, 227, 235, 0.5);
        border-radius: 10px;
        border-left: 15px solid #c90f2c;
        padding: 25px 0 25px 44px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 30px;
        color: rgba(51, 51, 51, 0.7);
        .right_title {
          color: #999999;
          margin-right: 28px;
        }
      }
    }
  }

  .no-result {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 50%;
    .img {
      width: 442px;
      height: 442px;
      background: url("../../assets/img/no-result.png") no-repeat;
      background-size: contain;
      margin-bottom: 44px;
    }
    .remark {
      width: 442px;
      text-align: center;
      font-size: 36px;
      line-height: 63px;
      letter-spacing: 0px;
      color: #333333;
      .btn {
        color: #d2102e;
      }
    }
  }
}
</style>
